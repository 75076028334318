.fs--xs pre {
  font-size: 10px !important;
}

.badge-xs {
  font-size: 7px !important;
}

.w-30 {
  width: 30%;
}

.form-check-input {
  background-color: white !important;
}
.form-check-input:checked {
  background-color: #5555ff !important;
}

.in-iframe .grecaptcha-badge {
  display: none !important;
}
