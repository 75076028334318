.translation-key {
  position: relative;

  .edit-translation-button {
    position:absolute;
    display: none;
    top: 3px;
    right: 0;
    background: white;

    .svg-inline--fa {
      margin: 0 !important;
    }

    svg {
      transform: scale(70%);
    }
  }

  &:hover {
    .edit-translation-button {
      display: block;
    }
  }
}