.data-action-group {
  background: white;

  .svg-inline--fa {
    margin: 0 !important;
  }

  svg {
    transform: scale(70%);
  }
}

.data-action-button {
  background: white;
  border: none;
  padding: 0;
  margin: 0;
  display: none !important;
  margin-top: 10px;

  button {
    font-size: 10px;
  }
}

.action-cell {
  &:hover {
    .data-action-button {
      display: block !important;
    }
  }
}