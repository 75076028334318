.data-path-row {
  position: relative;

  .data-action-group {
    position: absolute;
    top: 3px;
    display: none;
    right: 0;
  }

  &:hover {
    .data-action-group {
      display: block;
    }
  }
}

.actions-container {
  position: relative;
}