.border-left-separator {
  border-left: 2px #ced4da solid!important;
}
.status-table td {
  position: relative;
}
.bo-badge {
  position: absolute;
  top: 2px;
  right: 2px;
}