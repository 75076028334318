.ocr-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
}

.ocr_line {
  position: absolute;
  white-space: nowrap;
}

.ocrx_word {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
}

/* Style optionnel pour visualiser les mots */
.ocrx_word:hover {
  background: rgba(255, 255, 0, 0.2);
  outline: 1px solid yellow;
}
