.build-email .ql-editor{
  min-height: 300px !important;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.result-subject p {
    margin: 0;
}